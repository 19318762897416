define('ember-brf/controllers/search/result', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    application: Ember.inject.controller(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('nearSorting', ['name']);
    },


    nearPromise: Ember.computed('housingCoop.id', 'housingCoop.center.{latitude,longitude}', function () {
      var _this = this;

      var latitude = this.get('housingCoop.center.latitude');
      var longitude = this.get('housingCoop.center.longitude');
      if (latitude && longitude) {
        var params = {
          center: latitude + ',' + longitude,
          radius: 500,
          include: 'addresses',
          exclude: this.get('housingCoop.id')
        };
        var promise = this.store.query('housingCoop', params);
        promise.catch(function (e) {
          return _this.sendError(e);
        });
        return promise;
      }
    }),

    near: Ember.computed('nearPromise.{content,isFulfilled}', function () {
      return this.get('nearPromise.content');
    }),

    sortedNear: Ember.computed.sort('near', 'nearSorting'),

    reload: function reload() {
      var _this2 = this;

      if (this.housingCoop) {
        this.store.findRecord('housing-coop', this.housingCoop.id, { reload: true, include: 'areas,addresses,documents,annualReports,approvals' }).then(function (housingCoop) {
          _this2.set('housingCoop', housingCoop);
        });
      }
    },


    actions: {
      reload: function reload() {
        this.reload();
      }
    }
  });
});