define('ember-brf/routes/search', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    actions: {
      toggleSort: function toggleSort(sort) {
        if (sort == this.controller.sort) {
          this.controller.set('sortDir', this.controller.sortDir == 'asc' ? 'desc' : 'asc');
        } else {
          this.controller.set('sort', sort);
        }
      },
      refresh: function refresh() {
        this.controller.set('fetch', this.controller.fetchTask.perform());
      }
    }
  });
});