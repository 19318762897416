define('ember-brf/routes/auth/accept-terms', ['exports', 'ember-brf/config/environment', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _environment, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),

    checkIfUserHasAccepted: function checkIfUserHasAccepted() {
      var user = this.get('session.data.authenticated.user');

      if (!user) {
        this.transitionTo('auth.login');
      } else if (user.termsAccepted && user.termsAccepted.filter(function (t) {
        return t.subject == _environment.default.APP.currentTerms.subject && t.version == _environment.default.APP.currentTerms.version;
      }).length > 0) {
        this.transitionTo('index');
      }
    },
    beforeModel: function beforeModel() {
      this.checkIfUserHasAccepted();

      return this._super.apply(this, arguments);
    },
    model: function model() {
      var _this = this;

      return this.session.authenticate('authenticator:custom').catch(function () {
        _this.session.invalidate();
      });
    },
    setupController: function setupController() {
      this.checkIfUserHasAccepted();
    }
  });
});