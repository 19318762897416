define('ember-brf/models/approval', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Approval = _emberData.default.Model.extend({
    housingCoop: _emberData.default.belongsTo('housing-coop'),
    annualReport: _emberData.default.belongsTo('annual-report'),

    created: _emberData.default.attr('isodate'),
    updated: _emberData.default.attr('isodate'),

    fiscalYearEnd: _emberData.default.attr('datestring'),
    validUntil: _emberData.default.attr('datestring'),
    decision: _emberData.default.attr('string'),
    decisionText: _emberData.default.attr('string'),
    decisionStress: _emberData.default.attr('string'),
    decisionBy: _emberData.default.attr('string'),

    decisionChoices: Ember.computed(function () {
      return ['Godkänd snabb', 'Godkänd fördjupad', 'Godkänd med stress', 'Ej godkänd'];
    }),

    isValid: Ember.computed('validUntil', function () {
      return (0, _moment.default)(this.validUntil) > (0, _moment.default)();
    }),

    color: Ember.computed('decision', function () {
      if (this.decision == 'Godkänd snabb') {
        return 'success';
      } else if (this.decision == 'Godkänd snabb') {
        return 'success';
      } else if (this.decision == 'Godkänd fördjupad') {
        return 'success';
      } else if (this.decision == 'Godkänd med stress') {
        return 'success';
      } else if (this.decision == 'Ej godkänd') {
        return 'danger';
      }
    })
  });

  exports.default = Approval;
});