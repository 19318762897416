define('ember-brf/adapters/document', ['exports', 'ember-data', 'ember-inflector'], function (exports, _emberData, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend({
    host: '/api/booli',
    shouldReloadAll: function shouldReloadAll() {
      return false;
    },
    shouldReloadRecord: function shouldReloadRecord() {
      return false;
    },
    shouldBackgroundReloadAll: function shouldBackgroundReloadAll() {
      return false;
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },

    pathForType: function pathForType(type) {
      return Ember.String.dasherize((0, _emberInflector.pluralize)(type));
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      return this.buildURL('housing-coop', snapshot.record.get('housingCoop.id')) + '/documents';
    },
    urlForDeleteRecord: function urlForDeleteRecord(id, modelName, snapshot) {
      return this.buildURL('housing-coop', snapshot.record.get('housingCoop.id')) + '/documents/' + snapshot.record.get('id');
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);

      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

      return this.ajax(url, "POST", { data: data });
    }
  });
});