define('ember-brf/helpers/power-calendar-format-date', ['exports', 'ember-power-calendar/helpers/power-calendar-format-date'], function (exports, _powerCalendarFormatDate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _powerCalendarFormatDate.default;
    }
  });
  Object.defineProperty(exports, 'powerCalendarFormatDate', {
    enumerable: true,
    get: function () {
      return _powerCalendarFormatDate.powerCalendarFormatDate;
    }
  });
});