define('ember-brf/controllers/admin/statistics/details', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    statistics: Ember.inject.controller('admin.statistics'),
    email: null,
    type: 'annualReports',

    queryParams: {
      email: 'email',
      type: 'type'
    },

    emailChoices: Ember.computed('statistics.byEmails', function () {
      var byEmails = this.get('statistics.byEmails') || {};
      var emails = Object.keys(byEmails);
      var emailChoices = [{ label: "Alla användare", value: null }];
      emails.forEach(function (e) {
        return emailChoices.push({ label: e, value: e });
      });
      return emailChoices;
    }),

    results: Ember.computed('statistics.byEmails.@each.{annualReports,approvals}', 'email', function () {
      if (this.email) {
        var byEmails = this.statistics.byEmails || {};
        return byEmails[this.email] || {};
      } else {
        return this.get('statistics.results');
      }
    }),

    annualReports: Ember.computed.alias('results.annualReports'),
    approvals: Ember.computed.alias('results.approvals')
  });
});