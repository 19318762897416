define('ember-brf/routes/housing-coops/housing-coop/merge', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('housing-coop', params.other_id, { reload: true, include: 'areas,addresses,documents,annualReports,approvals' });
    },
    setupController: function setupController(controller, model) {
      var housingCoop = this.modelFor('housing-coops.housing-coop');
      controller.set('housingCoop', housingCoop);
      controller.set('otherHousingCoop', model);
    }
  });
});