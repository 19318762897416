define('ember-brf/models/area', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var typeNames = {
    'country': 'Land',
    'indexArea': 'Indexområde',
    'county': 'Län',
    'municipality': 'Kommun',
    'populatedArea': 'Tätort',
    'suburb': 'Stadsdel',
    'locality': 'Stadsdel',
    'undefined': 'Fritt namngivet',
    'userDefined': 'Område',
    'postcode': 'Postnummer',
    'streetName': 'Gata'
  };

  var Area = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    parent: _emberData.default.attr('number'),
    type: _emberData.default.attr(),
    path: _emberData.default.attr('string'),

    label: Ember.computed('name', 'type', function () {
      var type = this.type;
      var name = this.name;

      if (type == 'municipality' && name.indexOf('kommun') == -1) {
        if (!/.*[aeiouyåäös]$/.test(name)) {
          name += 's';
        }
        name += ' kommun';
      }

      if (type == 'suburb' || type == 'locality') {
        name += ' (stadsdel)';
      }

      return name;
    }),

    typeName: Ember.computed('type', function () {
      return typeNames[this.type] || 'Område';
    }),

    url: Ember.computed('path', function () {
      return 'https://www.booli.se/' + this.path + '/';
    })
  });

  exports.default = Area;
});