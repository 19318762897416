define('ember-brf/serializers/annual-report', ['exports', 'uuid', 'ember-data'], function (exports, _uuid, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    primaryKey: 'id',

    attrs: {
      loans: { embedded: 'always' }
    },

    keyForRelationship: function keyForRelationship(key, relationship) {
      if (key == 'housingCoop') {
        return 'housingCoopId';
      }
      return this._super(key, relationship);
    },


    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      Ember.assign(hash, this.serialize(record, options));
    },

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (Ember.isArray(payload)) {
        payload = { annualReports: payload };
      } else if (payload.id) {
        payload = { annualReports: [payload] };
      }

      // All instance in store needs an id so create one for loans
      if (payload.annualReports) {
        payload.annualReports.forEach(function (a) {
          if (a.loans) {
            a.loans.forEach(function (l) {
              if (!l.id) {
                l.id = (0, _uuid.v4)();
              }
            });
          }
        });
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});