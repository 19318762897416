define('ember-brf/components/active-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    classNames: 'activity',
    attributeBindings: ['title', 'style'],
    title: Ember.computed.alias('activity.user'),
    saturation: 100,
    lightness: 30,

    style: Ember.computed('activity.user', function () {
      var color = this.intToHSL(this.colorHash(this.get('activity.user')));
      return Ember.String.htmlSafe('background: ' + color + ';');
    }),

    colorHash: function colorHash(string) {
      var hash = 0;
      if (string.length == 0) return hash;
      for (var i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash; // Convert to 32bit integer
      }
      return hash;
    },
    intToHSL: function intToHSL(i) {
      var shortened = i % 360;
      return "hsl(" + shortened + "," + this.saturation + "%," + this.lightness + "%)";
    }
  });
});