define('ember-brf/routes/housing-coops/housing-coop/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController: function setupController(controller) {
      var housingCoop = this.modelFor('housing-coops.housing-coop');
      controller.set('housingCoop', housingCoop);
    }
  });
});