define('ember-brf/serializers/loan', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    isNewSerializerAPI: true,
    primaryKey: 'id',

    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      Ember.assign(hash, this.serialize(record, options));
    }
  });
});