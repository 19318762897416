define('ember-brf/controllers/housing-coops/housing-coop/document', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      edit: function edit(document) {
        var annualReport = this.housingCoop.annualReports.findBy('documentId', document.id);

        if (!annualReport) {
          annualReport = this.store.createRecord('annual-report', {
            documentId: document.id,
            housingCoop: this.housingCoop
          });
        }

        this.transitionToRoute('housing-coops.housing-coop.annual-report', annualReport);
      }
    }
  });
});