define('ember-brf/adapters/area', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend({
    host: '/api/booli',
    shouldReloadAll: function shouldReloadAll() {
      return false;
    },
    shouldReloadRecord: function shouldReloadRecord() {
      return false;
    },
    shouldBackgroundReloadAll: function shouldBackgroundReloadAll() {
      return false;
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.headers = {
        'Accept': 'application/vnd.booli-v3+json, application/json'
      };
    },


    query: function query(store, type, _query) {
      // Map name queries to q
      if (_query.name) {
        _query.q = _query.name;
        delete _query.name;
      }
      return this._super(store, type, _query).then(function (json) {
        if (_query.q && ('sverige'.indexOf(_query.q.toLowerCase()) != -1 || 'riket'.indexOf(_query.q.toLowerCase()) != -1)) {
          // Add Sverige to results
          json.unshift({
            "hidden": 0,
            "id": 77104,
            "name": "Sverige",
            "path": "sverige/77104",
            "type": "country"
          });
        }
        return json;
      });
    },

    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      if (query && query.parent && !id) {
        var parent = query.parent;
        delete query.parent;
        var baseUrl = this._super(modelName, id, snapshot, requestType, query);
        return baseUrl + '/' + parent + '/descendants';
      }
      return this._super(modelName, id, snapshot, requestType, query);
    }
  });
});