define('ember-brf/instance-initializers/handle-errors', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  var error = Ember.Logger.error;
  function initialize(appInstance) {
    var fastbootService = appInstance.lookup('service:fastboot');
    var rollbarService = appInstance.lookup('service:rollbar');
    var oldOnError = Ember.onerror || function () {};

    Ember.onerror = function () {
      var err = arguments.length <= 0 ? undefined : arguments[0];

      // Ignore network errors, only show message to user
      if (err.status === 0 || err instanceof _emberData.default.AbortError) {
        return;
      }

      // Ignore 404 errors
      if (err.status === 404 || err instanceof _emberData.default.NotFoundError) {
        return;
      }

      oldOnError.apply(undefined, arguments);
      var rollbarEnabled = rollbarService.get('enabled');

      if (rollbarEnabled) {
        rollbarService.error.apply(rollbarService, arguments);
      }

      if (!rollbarEnabled || Ember.testing) {
        if (fastbootService && fastbootService.get('isFastBoot')) {
          error(arguments.length <= 0 ? undefined : arguments[0]);
        } else {
          throw arguments.length <= 0 ? undefined : arguments[0];
        }
      }
    };
  }

  exports.default = { initialize: initialize };
});