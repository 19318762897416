define('ember-brf/adapters/approval', ['exports', 'ember-brf/adapters/booli-adapter'], function (exports, _booliAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _booliAdapter.default.extend({
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      return this.buildURL('housing-coop', snapshot.record.get('housingCoop.id')) + '/approvals';
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      return this.urlForCreateRecord(modelName, snapshot);
    },
    urlForDeleteRecord: function urlForDeleteRecord(id, modelName, snapshot) {
      return this.buildURL('housing-coop', snapshot.record.get('housingCoop.id')) + '/approvals/' + snapshot.record.get('id');
    }
  });
});