define('ember-brf/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),

    isAdmin: Ember.computed('session.data.authenticated.user', function () {
      var user = this.get('session.data.authenticated.user');
      return user && user.roles && user.roles.includes('brf_admin');
    }),

    isAuditor: Ember.computed('session.data.authenticated.user', function () {
      var user = this.get('session.data.authenticated.user');
      return user && user.roles && user.roles.includes('brf_audit');
    }),

    isEditor: Ember.computed('session.data.authenticated.user', function () {
      var user = this.get('session.data.authenticated.user');
      return user && user.roles && (user.roles.includes('brf') || user.roles.includes('brf_audit'));
    })
  });
});