define('ember-brf/routes/housing-coops/housing-coop', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    store: Ember.inject.service(),
    activity: Ember.inject.service(),

    model: function model(params) {
      return this.store.findRecord('housing-coop', params.housing_coop_id, { reload: true, include: 'areas,addresses,documents,annualReports,approvals' });
    },
    setupController: function setupController(controller, model) {
      controller.set('housingCoop', model);

      this.activity.register('edit', 'housing-coop', model.id);
    },
    deactivate: function deactivate() {
      this.activity.unregister();
    },


    actions: {
      refresh: function refresh() {
        this.refresh();
      },

      showAnnualReportModal: function showAnnualReportModal(annualReport) {
        this.controller.set('showingAnnualReportModal', annualReport);
      },
      dismissAnnualReportModal: function dismissAnnualReportModal() {
        if (this.controller.showingAnnualReportModal) {
          this.controller.set('showingAnnualReportModal', null);
        }
      },
      deleteAnnualReport: function deleteAnnualReport(annualReport) {
        if (this.controller.showingAnnualReportModal && this.controller.showingAnnualReportModal.id == annualReport.id) {
          this.controller.set('showingAnnualReportModal', null);
        }
        annualReport.deleteRecord();
        annualReport.save();
      },
      showApproval: function showApproval(approval) {
        this.controller.set('showingApproval', approval);
      },
      dismissApproval: function dismissApproval() {
        if (this.controller.showingApproval) {
          this.controller.set('showingApproval', null);
        }
      },
      deleteApproval: function deleteApproval(approval) {
        if (this.controller.showingApproval && this.controller.showingApproval.id == approval.id) {
          this.controller.set('showingApproval', null);
        }
        approval.deleteRecord();
        approval.save();
      },
      toggleDocumentVisible: function toggleDocumentVisible(document) {
        document.set('visible', !document.get('visible'));
        document.save();
      },
      editDocument: function editDocument(document) {
        this.controller.set('editingDocument', document);
      },
      dismissDocument: function dismissDocument() {
        if (this.controller.editingDocument) {
          this.controller.editingDocument.rollbackAttributes();
          this.controller.set('editingDocument', null);
        }
      },
      saveDocument: function saveDocument() {
        if (this.controller.editingDocument) {
          this.controller.editingDocument.save();
          this.controller.set('editingDocument', null);
        }
      },
      showDeleteDocumentModal: function showDeleteDocumentModal(document) {
        this.controller.set('showingDeleteDocumentModal', document);
      },
      dismissDeleteDocumentModal: function dismissDeleteDocumentModal() {
        if (this.controller.showingDeleteDocumentModal) {
          this.controller.set('showingDeleteDocumentModal', null);
        }
      },
      deleteDocument: function deleteDocument(document) {
        if (this.controller.showingDeleteDocumentModal && this.controller.showingDeleteDocumentModal.id == document.id) {
          this.controller.set('showingDeleteDocumentModal', null);
        }
        document.deleteRecord();
        document.save();
      }
    }
  });
});