define('ember-brf/services/activity', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(Ember.Evented, {
    updates: Ember.inject.service(),
    session: Ember.inject.service(),
    registered: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.activities = [];

      this.updates.on('activity', function (data) {
        if (data.status == 'active') {
          var activity = _this.activities.findBy('id', data.id);
          if (!activity) {
            _this.activities.pushObject(data);
          }
        } else if (data.status == 'removed') {
          var _activity = _this.activities.findBy('id', data.id);
          if (_activity) {
            _this.activities.removeObject(_activity);
          }
        }
      });

      this.updates.on('connect', function () {
        if (_this.registered) {
          _this.updates.send(_this.registered);
        }
      });

      this.updates.on('disconnect', function () {
        _this.activities.clear();
      });
    },
    register: function register(action, model, instanceId) {
      var email = this.get('session.data.authenticated.user.email');
      this.registered = {
        type: 'activity',
        method: 'post',
        user: email,
        action: action,
        model: model,
        instance_id: instanceId
      };

      if (this.updates.isConnected) {
        this.updates.send(this.registered);
      }
    },
    unregister: function unregister() {
      this.updates.send({
        type: 'activity',
        method: 'delete'
      });
    }
  });
});