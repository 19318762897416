define('ember-brf/models/loan', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Loan = _emberData.default.Model.extend({
    annualReport: _emberData.default.belongsTo('annual-report'),

    bank: _emberData.default.attr('string'),
    interest: _emberData.default.attr('number'),
    amount: _emberData.default.attr('number'),
    isFloating: _emberData.default.attr('boolean'),
    dateOfChange: _emberData.default.attr('datestring')
  });

  exports.default = Loan;
});