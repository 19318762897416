define('ember-brf/routes/admin', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),

    checkIfUserIsAdmin: function checkIfUserIsAdmin() {
      var user = this.get('session.data.authenticated.user');

      if (!user || !user.roles || !user.roles.includes('brf_admin')) {
        this.transitionTo('index');
      }
    },
    beforeModel: function beforeModel() {
      this.checkIfUserIsAdmin();

      return this._super.apply(this, arguments);
    }
  });
});