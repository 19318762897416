define('ember-brf/serializers/housing-coop', ['exports', 'uuid', 'ember-data'], function (exports, _uuid, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    primaryKey: 'booliId',
    attrs: {
      documents: { embedded: 'always' },
      annualReports: { embedded: 'always' },
      approvals: { embedded: 'always' }
    },

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var total = payload.totalCount;

      delete payload.totalCount;
      delete payload.offset;
      delete payload.limit;
      delete payload.count;

      if (Ember.isArray(payload)) {
        payload = { housingCoops: payload };
      } else if (payload.id) {
        payload.booliId = payload.id;
        delete payload.id;
        payload = { housingCoops: [payload] };
      }

      if (total) {
        payload.meta = { total: total };
      }

      if (payload.housingCoops) {
        payload.housingCoops.forEach(function (hc) {
          // Type field in documents breaks embedded records, rename it
          if (hc.documents) {
            hc.documents.forEach(function (d) {
              d._type = d.type;
              delete d.type;
            });
          }

          // All instance in store needs an id so create one for loans
          if (hc.annualReports) {
            hc.annualReports.forEach(function (a) {
              if (a.loans) {
                a.loans.forEach(function (l) {
                  if (!l.id) {
                    l.id = (0, _uuid.v4)();
                  }
                });
              }
            });
          }
        });
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      Ember.assign(hash, this.serialize(record, options));
    }
  });
});