define('ember-brf/components/paginated-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['paginated-list'],
    showPagination: true,
    perPage: 25,
    page: 1,

    numPages: Ember.computed('perPage', 'items.length', function () {
      return Math.ceil(this.get('items.length') / this.perPage);
    }),

    currentPage: Ember.computed('page', 'numPages', {
      get: function get() {
        var page = this.page;
        var numPages = this.numPages;
        return page > numPages ? numPages : page;
      },
      set: function set(key, value) {
        this.set('page', value);
        return value;
      }
    }),

    itemsChanged: Ember.observer('items', function () {
      // Go back to first page if the underlying items change
      var items = this.items;
      if (items !== this._oldItems) {
        this.set('page', 1);
      }
      this._oldItems = items;
    }),

    paginatedItems: Ember.computed('items', 'perPage', 'currentPage', function () {
      var items = this.items;
      var page = this.currentPage;
      var perPage = this.perPage;
      var i = (page - 1) * perPage;
      return items ? items.slice(i, i + perPage) : items;
    }),

    actions: {
      page: function page(_page) {
        var current = this.currentPage;
        var numPages = this.numPages;
        if (_page == "next") {
          _page = current + 1;
        } else if (_page == 'prev') {
          _page = current - 1;
        }

        if (_page > 0 && _page <= numPages) {
          this.set('currentPage', _page);
        }
      }
    }
  });
});