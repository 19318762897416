define('ember-brf/routes/search/result', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    store: Ember.inject.service(),

    model: function model(params) {
      return this.store.findRecord('housing-coop', params.housing_coop_id, { reload: true, include: 'areas,addresses,documents,annualReports,approvals' });
    },
    setupController: function setupController(controller, model) {
      controller.set('housingCoop', model);
    },


    actions: {}
  });
});