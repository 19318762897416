define('ember-brf/services/rollbar', ['exports', 'rollbar'], function (exports, _rollbar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    enabled: Ember.computed({
      get: function get() {
        return this.get('config.enabled');
      },
      set: function set(key, value) {
        this.get('notifier').configure({ enabled: value });
        return value;
      }
    }),

    currentUser: Ember.computed({
      get: function get() {},
      set: function set(key, value) {
        this.get('notifier').configure({ payload: { person: value } });
        return value;
      }
    }),

    notifier: Ember.computed(function () {
      return this.rollbarClient();
    }).readOnly(),

    config: Ember.computed(function () {
      return Ember.getOwner(this).resolveRegistration('config:environment').emberRollbarClient;
    }).readOnly(),

    rollbarClient: function rollbarClient() {
      var config = this.get('config');
      return new _rollbar.default(config);
    },


    // Notifications

    critical: function critical(message) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.get('notifier').critical(message, data);
    },
    error: function error(message) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.get('notifier').error(message, data);
    },
    warning: function warning(message) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.get('notifier').warning(message, data);
    },
    info: function info(message) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.get('notifier').info(message, data);
    },
    debug: function debug(message) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.get('notifier').debug(message, data);
    }
  });
});