define('ember-brf/serializers/area', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    isNewSerializerAPI: true,
    primaryKey: 'id',

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (!Ember.isArray(payload)) {
        payload = [payload];
      }

      // Remove all areas with type streetName
      for (var i = payload.length - 1; i >= 0; i--) {
        if (payload[i].type == 'streetName') {
          payload.splice(i, 1);
        }
      }

      payload = { areas: payload };

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});