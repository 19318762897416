define('ember-brf/components/json-field', ['exports', 'uuid'], function (exports, _uuid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['json-field'],

    value: Ember.computed('model', 'field', {
      get: function get() {
        return this.model.get(this.field.slug);
      },
      set: function set(key, value) {
        this.model.set(this.field.slug, value);
        return value;
      }
    }),

    actions: {
      addItem: function addItem() {
        this.value.createRecord({ id: (0, _uuid.v4)() });
      },
      removeItem: function removeItem(item) {
        this.value.removeObject(item);
      }
    }
  });
});