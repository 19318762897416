define('ember-brf/serializers/document', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    isNewSerializerAPI: true,
    primaryKey: 'id',

    keyForAttribute: function keyForAttribute(attr) {
      if (attr == 'type') {
        return '_type';
      }
      return this._super(attr);
    },
    keyForRelationship: function keyForRelationship(key, relationship) {
      if (key == 'housingCoop') {
        return 'housingCoopId';
      }
      return this._super(key, relationship);
    },


    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      Ember.assign(hash, this.serialize(record, options));
      hash.type = hash._type;
      delete hash._type;
    }
  });
});