define('ember-brf/utils/compare', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = compare;


  var TYPE_ORDER = {
    'undefined': 0,
    'null': 1,
    'boolean': 2,
    'number': 3,
    'string': 4,
    'array': 5,
    'object': 6,
    'instance': 7,
    'function': 8,
    'class': 9,
    'date': 10
  };

  function spaceship(a, b) {
    var diff = a - b;
    return (diff > 0) - (diff < 0);
  }

  function compare(v, w) {
    if (v === w) {
      return 0;
    }

    var type1 = Ember.typeOf(v);
    var type2 = Ember.typeOf(w);

    var res = spaceship(TYPE_ORDER[type1], TYPE_ORDER[type2]);

    if (res !== 0) {
      return res;
    }

    // types are equal - so we have to check values now
    switch (type1) {
      case 'boolean':
      case 'number':
        return spaceship(v, w);

      case 'string':
        return spaceship(v.localeCompare(w, 'sv-se'), 0);

      case 'array':
        {
          var vLen = v.length;
          var wLen = w.length;
          var len = Math.min(vLen, wLen);

          for (var i = 0; i < len; i++) {
            var r = compare(v[i], w[i]);
            if (r !== 0) {
              return r;
            }
          }

          // all elements are equal now
          // shorter array should be ordered first
          return spaceship(vLen, wLen);
        }
      case 'instance':
        return 0;

      case 'date':
        return spaceship(v.getTime(), w.getTime());

      default:
        return 0;
    }
  }
});