define('ember-brf/authenticators/custom', ['exports', 'ember-simple-auth/authenticators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    session: Ember.inject.service(),
    loginEndpoint: '/api/auth/login/',
    logoutEndpoint: '/api/auth/logout/',
    refreshEndpoint: '/api/auth/me/',
    acceptTermsEndpoint: '/api/auth/accept-terms/',

    restore: function restore() {
      return Ember.$.getJSON({
        url: this.refreshEndpoint
      });
    },
    authenticate: function authenticate(email, password) {
      if (email && password) {
        return Ember.$.ajax({
          url: this.loginEndpoint,
          type: 'POST',
          data: JSON.stringify({ email: email, password: password }),
          contentType: 'application/json',
          dataType: 'json'
        });
      } else {
        return Ember.$.getJSON({
          url: this.refreshEndpoint
        });
      }
    },
    invalidate: function invalidate() {
      return Ember.$.ajax({
        url: this.logoutEndpoint,
        type: 'POST',
        data: JSON.stringify({}),
        contentType: 'application/json',
        dataType: 'json'
      });
    },
    acceptTerms: function acceptTerms(subject, version, reference) {
      var _this = this;

      return Ember.$.ajax({
        url: this.acceptTermsEndpoint,
        type: 'POST',
        data: JSON.stringify({ subject: subject, version: version, reference: reference }),
        contentType: 'application/json',
        dataType: 'json'
      }).fail(function (jqXHR) {
        Ember.run(function () {
          if (jqXHR.status == 403) {
            _this.session.invalidate();
          }
        });
      }).then(function () {
        Ember.run(function () {
          // Reload user data
          return _this.session.authenticate('authenticator:custom');
        });
      }).then(function () {
        Ember.run(function () {
          // Manually notify the service that the authentication has changed
          _this.session.trigger('authenticationSucceeded');
        });
      });
    }
  });
});