define('ember-brf/components/number-input', ['exports', 'autonumeric'], function (exports, _autonumeric) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({
    digitGroupSeparator: ' ',
    decimalCharacter: ',',
    decimalCharacterAlternative: '.',
    decimalPlaces: 3,

    getOptions: function getOptions() {
      return {
        digitGroupSeparator: this.digitGroupSeparator,
        decimalCharacter: this.decimalCharacter,
        decimalCharacterAlternative: this.decimalCharacterAlternative,
        decimalPlaces: this.decimalPlaces || 0,
        allowDecimalPadding: (this.decimalPlaces || 0) == 0,
        showWarnings: false,
        modifyValueOnWheel: false,
        emptyInputBehavior: 'null'
      };
    },


    number: Ember.computed('value', 'options', {
      get: function get() {
        var options = this.getOptions();
        var string = _autonumeric.default.unformat(this.get('value'), options);
        return string !== "" ? Number(string) : null;
      },
      set: function set(key, value) {
        var options = this.getOptions();
        this.set('value', _autonumeric.default.format(value, options));
        if (this.autoNumeric && this.autoNumeric.getNumber() !== value) {
          this.autoNumeric.set(value);
        }
        return value;
      }
    }),

    didInsertElement: function didInsertElement() {
      var options = this.getOptions();
      this._super.apply(this, arguments);
      Ember.run.scheduleOnce('afterRender', this, function () {
        this.autoNumeric = new _autonumeric.default(this.element, options);
      });
    }
  });
});