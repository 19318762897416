define('ember-brf/components/mapbox-layer', ['exports', 'ember-brf/config/environment', 'ember-leaflet/components/tile-layer'], function (exports, _environment, _tileLayer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _tileLayer.default.extend({
    style: 'mapbox/streets-v11',
    tileSize: 512,
    maxZoom: 18,
    zoomOffset: -1,

    url: Ember.computed('style', 'accessToken', function () {
      return 'https://api.mapbox.com/styles/v1/' + this.style + '/tiles/{z}/{x}/{y}?access_token=' + _environment.default.mapbox.accessToken;
    })

  });
});