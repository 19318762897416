define('ember-brf/components/loading-icon', ['exports', 'ember-brf/templates/components/loading-icon'], function (exports, _loadingIcon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _loadingIcon.default,
    classNames: 'loader'
  });
});