define('ember-brf/components/form-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['form-group'],
    classNameBindings: ['isInline:form-group-inline'],
    attributeBindings: ['style'],

    init: function init() {
      this._super.apply(this, arguments);

      Ember.defineProperty(this, 'fieldIsDisabled', Ember.computed.alias('model.' + this.field.slug + 'Disabled'));
    },


    isUnverified: Ember.computed('model.unverifiedFields', {
      get: function get() {
        var unverifiedFields = (this.get('model.unverifiedFields') || '').split(',');
        return unverifiedFields.includes(this.field.slug);
      },
      set: function set(key, value) {
        var slug = this.field.slug;
        var unverifiedFields = (this.get('model.unverifiedFields') || '').split(',').filter(function (s) {
          return s != '';
        });
        if (value && !unverifiedFields.includes(slug)) {
          unverifiedFields.push(slug);
        } else if (!value) {
          unverifiedFields = unverifiedFields.filter(function (s) {
            return s != slug;
          });
        }
        this.set('model.unverifiedFields', unverifiedFields ? unverifiedFields.join(',') : null);
        return value;
      }
    }),

    error: Ember.computed('errors', 'field.slug', function () {
      return this.get('errors.' + this.field.slug);
    }),

    style: Ember.computed('field.width', function () {
      var width = this.get('field.width');

      if (width) {
        return 'width: ' + width + ';';
      } else {
        return null;
      }
    }),

    isValid: Ember.computed('error', function () {
      return !this.error;
    }),
    isInvalid: Ember.computed.not('isValid'),

    isDisabled: Ember.computed('field.readOnly', 'fieldIsDisabled', function () {
      return this.get('field.readOnly') || this.fieldIsDisabled;
    }),

    isInline: Ember.computed('field.{inline,type}', 'fieldset.inline', function () {
      var inline = this.get('field.inline');
      var fieldsetInline = this.get('fieldset.inline');
      if (inline == null) {
        if (fieldsetInline == null) {
          return this.get('field.type') == 'number';
        } else {
          return fieldsetInline;
        }
      } else {
        return inline;
      }
    })

  });
});