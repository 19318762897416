define('ember-brf/routes/housing-coops/housing-coop/annual-report', ['exports', 'moment', 'uuid'], function (exports, _moment, _uuid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      var housingCoop = this.modelFor('housing-coops.housing-coop');
      var annualReport = housingCoop.annualReports.findBy('id', '' + params.annual_report_id);

      if (annualReport) {
        return annualReport;
      } else {
        this.transitionTo('housing-coops.housing-coop.index');
      }
    },
    setupController: function setupController(controller, annualReport) {
      controller.set('annualReport', annualReport);

      var housingCoop = this.modelFor('housing-coops.housing-coop');
      controller.set('housingCoop', housingCoop);

      if (annualReport.documentId) {
        var document = housingCoop.documents.findBy('id', '' + annualReport.documentId);
        controller.set('document', document);
      }

      // Pre-fill with data from latest verified report
      if (!annualReport.verifiedBy && housingCoop.annualReport) {
        if (!annualReport.fiscalYearStart) {
          annualReport.set('fiscalYearStart', (0, _moment.default)(housingCoop.annualReport.fiscalYearEnd).add(1, 'days').format('YYYY-MM-DD'));
        }
        if (!annualReport.fiscalYearEnd) {
          annualReport.set('fiscalYearEnd', (0, _moment.default)(housingCoop.annualReport.fiscalYearEnd).add(1, 'years').format('YYYY-MM-DD'));
        }
        if (!annualReport.year) {
          annualReport.set('year', housingCoop.annualReport.year + 1);
        }
        if (!annualReport.loans || annualReport.loans.length == 0) {
          housingCoop.annualReport.loans.forEach(function (l) {
            var loan = annualReport.loans.createRecord({
              id: (0, _uuid.v4)()
            });
            loan.set('bank', l.bank);
            loan.set('interest', l.interest);
            loan.set('amount', l.amount);
            loan.set('isFloating', l.isFloating);
            loan.set('dateOfChange', l.dateOfChange);
            loan.set('annualReport', annualReport);
          });
        }

        var fieldsToCopy = ['housingCoopType', 'plotIsLeased', 'isHeatingIncludedInRent', 'constructionYear', 'insuranceCompany', 'numberOfUnits', 'numberOfRentalUnits', 'numberOfCommercialUnits', 'numberOfParkingSpaces', 'totalLivingArea', 'totalRentalArea', 'totalCommercialArea', 'totalCommercialAreaHousingCoop', 'totalPlotArea', 'taxAssessmentValue', 'taxAssessmentValueBuilding', 'taxAssessmentValueHousing', 'taxAssessmentValuePlot', 'taxAssessmentConstructionYear', 'unverifiedFields', 'financialManagementCompany', 'technicalManagementCompany', 'comment'];
        fieldsToCopy.forEach(function (field) {
          if (!annualReport[field]) {
            annualReport.set(field, housingCoop.annualReport.get(field));
          }
        });
      }
    }
  });
});