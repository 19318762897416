define('ember-brf/controllers/housing-coops/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      done: function done() {
        var hc = this.housingCoop;
        if (hc && hc.id) {
          this.transitionToRoute('housing-coops.housing-coop', hc.id);
        } else {
          this.transitionToRoute('housing-coops');
        }
      }
    }
  });
});