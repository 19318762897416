define('ember-brf/models/document', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Document = _emberData.default.Model.extend({
    housingCoop: _emberData.default.belongsTo('housing-coop'),

    name: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    file: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    source: _emberData.default.attr('string'),
    visible: _emberData.default.attr('boolean'),

    hidden: Ember.computed.not('visible'),

    typeChoices: Ember.computed(function () {
      return [{ name: '-', value: null }, { name: 'Årsredovisning', value: "annualreport" }, { name: 'Ekonomisk plan', value: "economicplan" }, { name: 'Stadgar', value: "byelaws" }, { name: 'Broschyr', value: "brochure" }, { name: 'Energideklaration', value: "energideklaration" }, { name: 'Projektinformation', value: "project" }, { name: 'Prislista', value: "pricelist" }];
    })
  });

  exports.default = Document;
});