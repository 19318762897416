define('ember-brf/routes/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),

    beforeModel: function beforeModel() {
      var user = this.get('session.data.authenticated.user');

      if (user && user.roles && (user.roles.includes('brf') || user.roles.includes('brf_audit'))) {
        this.transitionTo('housing-coops.index');
      } else {
        this.transitionTo('search.index');
      }
    }
  });
});