define('ember-brf/router', ['exports', 'ember-brf/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('housing-coops', function () {
      this.route('new');
      this.route('housing-coop', { path: ':housing_coop_id' }, function () {
        this.route('edit');
        this.route('approval');
        this.route('merge', { path: '/merge/:other_id' });
        this.route('annual-report', { path: '/annual-report/:annual_report_id' });
        this.route('document', { path: '/document/:document_id' });
      });
    });
    this.route('search', function () {
      this.route('result', { path: ':housing_coop_id' }, function () {
        this.route('annual-report');
      });
    });
    this.route('admin', function () {
      this.route('statistics', function () {
        this.route('details');
      });
    });
    this.route('auth', function () {
      this.route('login');
      this.route('logout');
      this.route('reset-password');
    });
    this.route('oauth2', function () {
      this.route('catch-all', { path: '/*path' });
    });
  });

  exports.default = Router;
});