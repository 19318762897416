define('ember-brf/controllers/error', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    isNetworkError: Ember.computed('model.{status,message}', function () {
      return this.model.status === 0 || this.model instanceof _emberData.default.AbortError;
    }),

    isNotFoundError: Ember.computed('model.status', function () {
      return this.model.status === 404 || this.model instanceof _emberData.default.NotFoundError;
    }),

    isOtherError: Ember.computed('isNetworkError', 'isNotFoundError', function () {
      return !this.isNetworkError && !this.isNotFoundError;
    }),

    previousTransition: Ember.computed('model.transition', function () {
      return this.model.transition;
    }),

    actions: {
      retry: function retry() {
        if (this.model.transition && this.model.transition.retry) {
          this.model.transition.retry();
        } else {
          window.location.replace(window.location.href);
        }
      }
    }
  });
});