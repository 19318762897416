define('ember-brf/routes/housing-coops/housing-coop/document', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      var housingCoop = this.modelFor('housing-coops.housing-coop');
      var doc = housingCoop.documents.findBy('id', '' + params.document_id);

      if (doc) {
        return doc;
      } else {
        this.transitionTo('housing-coops.housing-coop.index');
      }
    },
    setupController: function setupController(controller, doc) {
      controller.set('document', doc);

      var housingCoop = this.modelFor('housing-coops.housing-coop');
      controller.set('housingCoop', housingCoop);

      var annualReport = housingCoop.annualReports.findBy('documentId', doc.id);
      controller.set('annualReport', annualReport);
    }
  });
});