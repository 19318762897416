define('ember-brf/controllers/housing-coops/housing-coop/merge', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    housingCoopController: Ember.inject.controller('housing-coops.housing-coop'),

    actions: {
      done: function done(newId) {
        if (newId) {
          var currentId = this.get('housingCoopController.housingCoop.id');
          if ("" + currentId == "" + newId) {
            this.send('refresh');
            this.transitionToRoute('housing-coops.housing-coop');
          } else {
            this.transitionToRoute('housing-coops.housing-coop', newId);
          }
        } else {
          this.transitionToRoute('housing-coops.housing-coop');
        }
      }
    }
  });
});