define('ember-brf/utils/validation', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.validateYear = validateYear;
  exports.validateInt = validateInt;
  exports.validateUnsignedInt = validateUnsignedInt;
  exports.validatePercent = validatePercent;
  exports.validateDate = validateDate;
  exports.validateAssumedRate = validateAssumedRate;
  exports.validateFiscalYearStart = validateFiscalYearStart;
  exports.validateFiscalYearEnd = validateFiscalYearEnd;
  exports.validateMeanLivingArea = validateMeanLivingArea;
  exports.validateNetIncome = validateNetIncome;
  exports.validateNumberOfRentalUnits = validateNumberOfRentalUnits;
  exports.validateBalance = validateBalance;
  function validateYear(value) {
    if (!value) {
      return null;
    }
    if (value < 1600 || value > 2200) {
      return 'Ogiltigt årtal';
    }
    return null;
  }

  function validateInt(value) {
    if (!value) {
      return null;
    }
    if (!Number.isInteger(value)) {
      return 'Måste vara ett heltal';
    }
    return null;
  }

  function validateUnsignedInt(value) {
    var error = validateInt(value);
    if (error) {
      return error;
    }
    if (value < 0) {
      return 'Måste vara positivt';
    }
    if (value > 4294967295) {
      return 'För stort tal';
    }
    return null;
  }

  function validatePercent(value) {
    if (!value) {
      return null;
    }
    if (Number.isNaN(value)) {
      return 'Måste vara en siffra';
    }
    if (value > 999) {
      return 'För högt';
    }
    if (value < -999) {
      return 'För lågt';
    }
    return null;
  }

  function validateDate(value) {
    if (!value) {
      return null;
    }

    var m = (0, _moment.default)(value, 'YYYY-MM-DD');
    if (!m.isValid()) {
      return 'Ogiltigt datum';
    }

    return null;
  }

  function validateAssumedRate(value) {
    if (!value) {
      return null;
    }

    if (value > 25) {
      return 'Angiven ränta är för hög';
    }

    return null;
  }

  function validateFiscalYearStart(value, annualReport) {
    if (!value) {
      return null;
    }

    var m = (0, _moment.default)(value, 'YYYY-MM-DD');
    if (!m.isValid()) {
      return 'Ogiltigt datum';
    }

    if (annualReport.year) {
      var diff = parseInt(annualReport.year) - parseInt(value.substr(0, 4));
      if (diff > 2) {
        return 'För långt före angivet årtal';
      }
      if (diff < 0) {
        return 'Måste vara före angivet årtal';
      }
    }

    return null;
  }

  function validateFiscalYearEnd(value, annualReport) {
    if (!value) {
      return null;
    }

    var m = (0, _moment.default)(value, 'YYYY-MM-DD');
    if (!m.isValid()) {
      return 'Ogiltigt datum';
    }

    if (annualReport.document.type == 'annualreport' && m.isAfter()) {
      return 'I framtiden';
    }

    if (annualReport.fiscalYearStart) {
      var mStart = (0, _moment.default)(annualReport.fiscalYearStart);
      if (mStart.isValid()) {
        var diff = m.diff(mStart, 'years', true);
        if (diff < 0) {
          return 'Måste vara efter start';
        }
        if (diff > 1.6) {
          return 'För långt räkenskapsår';
        }
      }
    }

    return null;
  }

  function validateMeanLivingArea(value, annualReport) {
    if (!value || !(value > 0)) {
      return 'Måste fyllas i';
    }

    var error = validateUnsignedInt(value);
    if (error) {
      return error;
    }

    if (annualReport.meanLivingArea != null) {
      if (annualReport.meanLivingArea < 18) {
        return 'Boarea/lgh måste vara minst 18';
      } else if (annualReport.meanLivingArea > 300) {
        return 'Boarea/lgh måste vara under 300';
      }
    }
  }

  function validateNetIncome(value, annualReport) {
    var netIncome = annualReport.netIncome;
    var calculatedNetIncome = annualReport.netIncomeAfterFinancialItems - (annualReport.otherTax || 0);

    if (netIncome && !(Math.abs(netIncome - calculatedNetIncome) < 2000)) {
      return 'Resultat måste stämma med inkomster - kostnader';
    }
  }

  function validateNumberOfRentalUnits(value, annualReport) {
    var error = validateUnsignedInt(value);
    if (error) {
      return error;
    }

    if (value > 0 && annualReport.numberOfUnits > 0 && value > annualReport.numberOfUnits) {
      return 'Kan inte vara fler än antal lägenheter';
    }
  }

  function validateBalance(value, annualReport) {
    if (Math.abs(annualReport.totalAssets - annualReport.totalEquityAndLoans) > 2000) {
      return 'Tillgångar måste vara lika med kapital & skulder';
    }
  }
});