define('ember-brf/routes/housing-coops/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController: function setupController(controller) {
      var housingCoop = this.store.createRecord('housing-coop', {});
      controller.set('housingCoop', housingCoop);
    }
  });
});