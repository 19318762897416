define('ember-brf/services/updates', ['exports', 'reconnecting-websocket'], function (exports, _reconnectingWebsocket) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(Ember.Evented, {
    isConnected: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var protocol = window.location.protocol == 'https:' ? 'wss' : 'ws';
      this.socket = new _reconnectingWebsocket.default(protocol + '://' + window.location.host + '/ws/events/');

      if (window && window.addEventListener) {
        window.addEventListener('unload', this, false);
      }

      this.lastSeenEvent = null;
      this.lastSeenActivity = 0;

      // Process each incoming message
      this.socket.onmessage = function (e) {
        var messages = JSON.parse(e.data);
        if (!Array.isArray(messages)) {
          messages = [messages];
        }

        messages.forEach(function (message) {
          if (message.type == 'event') {
            _this.lastSeenEvent = message.id;
            _this.trigger('event', message.data);
          }

          if (message.type == 'activity') {
            _this.lastSeenActivity = message.id;
            _this.trigger('activity', message);
          }
        });
      };

      // Fetch any missed messages when reconnecting
      this.socket.onopen = function () {
        _this.isConnected = true;

        if (_this.lastSeenEvent != null) {
          _this.socket.send(JSON.stringify({
            type: 'event',
            method: 'GET',
            after: _this.lastSeenEvent
          }));
        }

        _this.socket.send(JSON.stringify({
          type: 'activity',
          method: 'GET',
          after: _this.lastSeenActivity
        }));

        _this.trigger('connect');
      };

      this.socket.onclose = function () {
        _this.isConnected = false;
        _this.trigger('disconnect');
      };
    },
    send: function send(data) {
      this.socket.send(JSON.stringify(data));
    },
    onUnload: function onUnload() {
      this.socket.close();
    },
    handleEvent: function handleEvent(event) {
      var fnName = event.type.split('');

      if (fnName.length) {
        fnName[0] = fnName[0].toUpperCase();
        var fn = this['on' + fnName.join('')];

        if (typeof fn === 'function') {
          fn.apply(this, arguments);
        }
      }
    }
  });
});