define('ember-brf/session-stores/application', ['exports', 'ember-simple-auth/session-stores/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    persist: function persist() {
      return Ember.RSVP.resolve();
    },
    restore: function restore() {
      // Resolve with empty data and let the authenticator handle restoring
      return Ember.RSVP.resolve({
        authenticated: {
          authenticator: "authenticator:custom",
          user: {}
        }
      });
    },
    clear: function clear() {
      return Ember.RSVP.resolve();
    }
  });
});