define('ember-brf/components/date-input', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    date: Ember.computed('value', {
      get: function get() {
        var m = (0, _moment.default)(this.value, ['YYYYMMDD', 'YYYY-MM-DD'], true);
        if (m.isValid()) {
          return m;
        } else {
          return null;
        }
      },
      set: function set(key, value) {
        if (value && value.isValid()) {
          this.set('value', value.format('YYYY-MM-DD'));
        } else if (!value) {
          this.set('value', null);
        }
        return value;
      }
    }),

    inputValue: Ember.computed('date', {
      get: function get() {
        var m = this.get('date');
        if (m && m.isValid()) {
          return m.format('YYYY-MM-DD');
        } else {
          return null;
        }
      },
      set: function set(key, value) {
        var m = (0, _moment.default)(value, ['YYYYMMDD', 'YYYY-MM-DD'], true);
        if (m.isValid()) {
          this.set('date', m);
          return m.format('YYYY-MM-DD');
        } else if (!value) {
          this.set('date', null);
        }
        return value;
      }
    }),

    actions: {
      select: function select(date) {
        this.set('date', date);
        this.$('#' + this.inputId).focus();
      },
      closeLater: function closeLater(dropdown) {
        Ember.run.later(function () {
          dropdown.actions.close();
        }, 200);
      }
    }
  });
});