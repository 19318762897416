define('ember-brf/components/map-addresses', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['map-addresses'],
    padding: 0.05,

    markers: Ember.computed('addresses', function () {
      var addresses = this.addresses || [];
      return addresses.map(function (a) {
        return { location: L.latLng(a.latitude, a.longitude), title: a.postAddress };
      });
    }),

    bounds: Ember.computed('markers', function () {
      var latLngs = this.markers.mapBy('location');
      return latLngs && latLngs.length > 0 ? L.polygon(latLngs).getBounds().pad(-this.padding) : null;
    })
  });
});