define('ember-brf/controllers/housing-coops/housing-coop/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    housingCoopController: Ember.inject.controller('housing-coops.housing-coop'),
    housingCoop: Ember.computed.alias('housingCoopController.housingCoop'),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('nearSorting', ['name']);
    },


    nearPromise: Ember.computed('housingCoop.id', 'housingCoop.center.{latitude,longitude}', function () {
      var _this = this;

      var latitude = this.get('housingCoop.center.latitude');
      var longitude = this.get('housingCoop.center.longitude');
      if (latitude && longitude) {
        var params = {
          center: latitude + ',' + longitude,
          radius: 500,
          include: 'addresses',
          exclude: this.get('housingCoop.id')
        };
        var promise = this.store.query('housingCoop', params);
        promise.catch(function (e) {
          return _this.sendError(e);
        });
        return promise;
      }
    }),

    near: Ember.computed('nearPromise.{content,isFulfilled}', function () {
      return this.get('nearPromise.content');
    }),

    sortedNear: Ember.computed.sort('near', 'nearSorting'),

    actions: {
      reload: function reload() {
        this.housingCoopController.reload();
      }
    }
  });
});