define('ember-brf/initializers/add-csrf-token', ['exports', 'ember-brf/utils/get-cookie'], function (exports, _getCookie) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: "csrftoken",

    initialize: function initialize() {

      function csrfSafeMethod(method) {
        // these HTTP methods do not require CSRF protection
        return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method)
        );
      }

      // Get the CSRF token from the cookie
      Ember.$.ajaxSetup({
        beforeSend: function beforeSend(xhr, settings) {
          if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
            xhr.setRequestHeader("X-CSRFToken", (0, _getCookie.default)('csrftoken'));
          }
        }
      });
    }
  };
});