define('ember-brf/transforms/datetimestring', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized == '0000-00-00 00:00:00') {
        serialized = null;
      }

      return serialized;
    },

    serialize: function serialize(deserialized) {
      return deserialized ? (0, _moment.default)(deserialized).format('YYYY-MM-DD HH:mm:ss') : null;
    }
  });
});