define('ember-brf/components/dropdown-btn-trigger', ['exports', 'ember-basic-dropdown/components/basic-dropdown/trigger'], function (exports, _trigger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _trigger.default.extend({
    tagName: 'button',
    classNameBindings: ['activeClass'],

    activeClass: Ember.computed('dropdown.isOpen', function () {
      if (this.get('dropdown.isOpen')) {
        return 'active';
      }
    })
  });
});