define('ember-brf/serializers/approval', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    isNewSerializerAPI: true,
    primaryKey: 'id',

    keyForRelationship: function keyForRelationship(key, relationship) {
      if (key == 'housingCoop') {
        return 'housingCoopId';
      }
      if (key == 'annualReport') {
        return 'annualReportId';
      }
      return this._super(key, relationship);
    },


    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      Ember.assign(hash, this.serialize(record, options));
    },

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (Ember.isArray(payload)) {
        payload = { approvals: payload };
      } else if (payload.id) {
        payload = { approvals: [payload] };
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});