define('ember-brf/controllers/search/result/annual-report', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    result: Ember.inject.controller('search.result'),
    housingCoop: Ember.computed.alias('result.housingCoop')
  });
});