define('ember-brf/components/active-users', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['active-users'],
    activity: Ember.inject.service(),
    model: null,
    id: null,

    activities: Ember.computed('activity.activities.@each.{model,instance_id}', 'model', 'id', function () {
      var id = this.get('id');
      var model = this.get('model');
      var activities = this.activity.activities.filter(function (a) {
        return a.model == model && a.instance_id == id;
      });
      return activities.uniqBy('user');
    })
  });
});