define('ember-brf/routes/housing-coops', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),

    checkIfUserIsEditor: function checkIfUserIsEditor() {
      var user = this.get('session.data.authenticated.user');

      if (!user || !user.roles || !(user.roles.includes('brf') || user.roles.includes('brf_audit'))) {
        this.transitionTo('index');
      }
    },
    beforeModel: function beforeModel() {
      this.checkIfUserIsEditor();

      return this._super.apply(this, arguments);
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('fetch', controller.fetchTask.perform());
    },


    actions: {
      toggleSort: function toggleSort(sort) {
        if (sort == this.controller.sort) {
          this.controller.set('sortDir', this.controller.sortDir == 'asc' ? 'desc' : 'asc');
        } else {
          this.controller.set('sort', sort);
        }
      },
      refresh: function refresh() {
        this.controller.set('fetch', this.controller.fetchTask.perform());
      }
    }
  });
});