define('ember-brf/controllers/housing-coops/housing-coop/approval', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      done: function done() {
        this.transitionToRoute('housing-coops.housing-coop');
      }
    }
  });
});