define('ember-brf/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'ember-data'], function (exports, _applicationRouteMixin, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    session: Ember.inject.service(),
    rollbar: Ember.inject.service(),

    afterModel: function afterModel() {},
    sessionAuthenticated: function sessionAuthenticated() {
      this._super.apply(this, arguments);
    },
    sessionInvalidated: function sessionInvalidated() {
      this._super.apply(this, arguments);
    },


    actions: {
      error: function error(_error, transition) {
        _error.transition = transition;

        // Access denied, log out user
        if (_error.status === 403 || _error instanceof _emberData.default.ForbiddenError) {
          this.session.invalidate();
          return false;
        }

        return true;
      }
    }
  });
});