define('ember-brf/adapters/housing-coop', ['exports', 'ember-brf/adapters/booli-adapter'], function (exports, _booliAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _booliAdapter.default.extend({
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      // Special case for queue queries
      if (query && query.queue) {
        delete query.queue;
        var baseUrl = this._super(modelName, id, snapshot, requestType, query);
        return baseUrl + '/queue';
      }

      return this._super(modelName, id, snapshot, requestType, query);
    },

    query: function query(store, type, _query) {
      // Map name queries to q
      var exclude = null;
      if (_query.exclude) {
        exclude = _query.exclude + '';
        delete _query.exclude;
      }
      if (_query.include && _query.include.includes('annualReports')) {
        _query['annualReports.verified'] = 0;
      }
      return this._super(store, type, _query).then(function (result) {
        if (exclude && result.housingCoops) {
          // Exclude a specific id
          result.housingCoops = result.housingCoops.filter(function (hc) {
            return '' + hc.booliId != exclude;
          });
        }
        return result;
      });
    },

    buildQuery: function buildQuery(snapshot) {
      var query = {};

      if (snapshot) {
        var include = snapshot.include;


        if (include) {
          query.include = include;
          if (include.includes('annualReports')) {
            query['annualReports.verified'] = 0;
          }
        }
      }

      return query;
    }
  });
});