define('ember-brf/services/addresses', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    apiUrl: '/api/booli/addresses',

    search: function search(query) {
      return Ember.$.getJSON(this.apiUrl, query);
    }
  });
});